// Fonts
@import url('https://fonts.googleapis.com/css?family=Ubuntu');

// Variables
@import 'variables';

// Font Awesome
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

// Bootstrap
//@import '~bootstrap/scss/bootstrap';

// AdminLTE
@import '~admin-lte/dist/css/adminlte.css';

// Overlayscrollbars
//@import '~overlayscrollbars/css/OverlayScrollbars.css';

// Daterange picker
//@import '~daterangepicker/daterangepicker.css';
