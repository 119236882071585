.app-sidebar {
  --#{$lte-prefix}sidebar-hover-bg: #{$lte-sidebar-hover-bg};
  --#{$lte-prefix}sidebar-color: #{$lte-sidebar-color};
  --#{$lte-prefix}sidebar-hover-color: #{$lte-sidebar-hover-color};
  --#{$lte-prefix}sidebar-active-color: #{$lte-sidebar-active-color};
  --#{$lte-prefix}sidebar-menu-active-bg: #{$lte-sidebar-menu-active-bg};
  --#{$lte-prefix}sidebar-menu-active-color: #{$lte-sidebar-menu-active-color};
  --#{$lte-prefix}sidebar-submenu-bg: #{$lte-sidebar-submenu-bg};
  --#{$lte-prefix}sidebar-submenu-color: #{$lte-sidebar-submenu-color};
  --#{$lte-prefix}sidebar-submenu-hover-color: #{$lte-sidebar-submenu-hover-color};
  --#{$lte-prefix}sidebar-submenu-hover-bg: #{$lte-sidebar-submenu-hover-bg};
  --#{$lte-prefix}sidebar-submenu-active-color: #{$lte-sidebar-submenu-active-color};
  --#{$lte-prefix}sidebar-submenu-active-bg: #{$lte-sidebar-submenu-active-bg};
  --#{$lte-prefix}sidebar-header-color: #{$lte-sidebar-header-color};

  z-index: $lte-zindex-sidebar;
  grid-area: #{$lte-prefix}app-sidebar;
  min-width: var(--#{$lte-prefix}sidebar-width);
  max-width: var(--#{$lte-prefix}sidebar-width);
  @include transition($lte-sidebar-transition);
}

.sidebar-brand {
  display: flex;
  justify-content: space-between;
  padding: $lte-brand-link-padding-y $lte-brand-link-padding-x;
  overflow: hidden;
  font-size: $navbar-brand-font-size;
  white-space: nowrap;
  border-bottom: $lte-brand-link-border-buttom solid var(--#{$prefix}border-color);
  @include transition(width $lte-transition-speed $lte-transition-fn);

  .brand-link {
    flex: 1;
    &:hover {
      text-decoration: none;
    }

    .brand-image {
      float: left;
      width: auto;
      max-height: 33px;
      margin-top: -3px;
      margin-right: .5rem;
      margin-left: .8rem;
      line-height: .8;
    }
  }

  .brand-text {
    color: rgba(var(--#{$prefix}emphasis-color-rgb), .8);
    @include transition(flex $lte-transition-speed $lte-transition-fn, width $lte-transition-speed $lte-transition-fn);
    &:hover {
      color: var(--#{$prefix}emphasis-color);
    }
  }
}

.sidebar-wrapper {
  padding-top: $lte-sidebar-padding-y;
  padding-right: $lte-sidebar-padding-x;
  padding-bottom: $lte-sidebar-padding-y;
  padding-left: $lte-sidebar-padding-x;
  overflow-x: hidden;
  overflow-y: auto;
  @include scrollbar-color-gray();
  @include scrollbar-width-thin();

  // Sidebar Menu. First level links
  .sidebar-menu > .nav-item {
    // links
    > .nav-link {
      &:active,
      &:focus {
        color: var(--#{$lte-prefix}sidebar-color);
      }
    }

    > .nav-link.active:not(:hover) {
      color: var(--#{$lte-prefix}sidebar-menu-active-color) !important;
      background-color: var(--#{$lte-prefix}sidebar-menu-active-bg) !important;
    }

    // Hover and active states
    &.menu-open > .nav-link,
    &:hover > .nav-link,
    > .nav-link:focus  {
      color: var(--#{$lte-prefix}sidebar-hover-color);
      background-color: var(--#{$lte-prefix}sidebar-hover-bg);
    }

    // First Level Submenu
    > .nav-treeview {
      background-color: var(--#{$lte-prefix}sidebar-submenu-bg);
    }
  }

  // Section Heading
  .nav-header {
    color: var(--#{$lte-prefix}sidebar-header-color);
    background-color: inherit;
  }

  // All links within the sidebar menu
  a {
    color: var(--#{$lte-prefix}sidebar-color);

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  // All submenus
  .nav-treeview {
    > .nav-item {
      > .nav-link {
        color: var(--#{$lte-prefix}sidebar-submenu-color);

        &:hover,
        &:focus {
          color: var(--#{$lte-prefix}sidebar-submenu-hover-color);
          // background-color: var(--#{$lte-prefix}sidebar-submenu-hover-bg);
        }
      }

      > .nav-link.active {
        &,
        &:hover,
        &:focus {
          color: var(--#{$lte-prefix}sidebar-submenu-active-color);
          background-color: var(--#{$lte-prefix}sidebar-submenu-active-bg);
        }
      }

      > .nav-link:hover {
        background-color: var(--#{$lte-prefix}sidebar-submenu-hover-bg);
      }
    }
  }
}

// Sidebar navigation menu
.sidebar-menu {
  // All levels
  .nav-item {
    > .nav-link {
      margin-bottom: .2rem;

      .nav-arrow {
        @include transition(transform $lte-transition-fn $lte-transition-speed);
        transform: none #{"/*rtl:rotate(180deg)*/"};
      }
    }
  }

  // All levels
  .nav-link > .nav-badge,
  .nav-link > p > .nav-badge {
    position: absolute;
    right: 1rem;
  }

  .nav-link > .nav-arrow,
  .nav-link > p > .nav-arrow {
    position: absolute;
    top: .7rem;
    right: 1rem;
  }

  .nav-link {
    position: relative;
    width: subtract(var(--#{$lte-prefix}sidebar-width), $lte-sidebar-padding-x * 2);
    @include transition(width $lte-transition-fn $lte-transition-speed);
    @include border-radius($border-radius);

    p {
      display: inline;
      padding-left: .5rem;
      margin: 0;
    }

    .nav-icon {
      padding-left: .3rem;
    }
  }

  .nav-header {
    padding: $nav-link-padding-y ($nav-link-padding-y * 1.5);
    font-size: .9rem;
  }

  // Tree view menu
  .nav-treeview {
    display: none;
    padding: 0;
    list-style: none;
  }

  .menu-open {
    > .nav-treeview {
      display: block;
    }

    > .nav-link {
      .nav-arrow {
        transform: rotate(90deg) #{"/*rtl:rotate(90deg)*/"};
      }
    }
  }
}

// A fix for text overflow while transitioning from sidebar mini to full sidebar
.sidebar-menu,
.sidebar-menu > .nav-header,
.sidebar-menu .nav-link {
  white-space: nowrap;
}

.sidebar-mini.sidebar-collapse {
  .app-sidebar {
    min-width: $lte-sidebar-mini-width;
    max-width: $lte-sidebar-mini-width;
  }

  // Make the sidebar headers
  .sidebar-menu .nav-header {
    display: none;
  }

  .sidebar-menu {
    .nav-link {
      width: $lte-sidebar-mini-width - $lte-sidebar-padding-x * 2;
    }
  }

  .sidebar-menu .nav-link p {
    width: 0;
    white-space: nowrap;
  }

  .sidebar-menu .nav-link p,
  .brand-text {
    visibility: hidden;
    animation-name: fadeOut;
    animation-fill-mode: both;
  }

  &.sidebar-is-hover {
    .app-sidebar {
      min-width: var(--#{$lte-prefix}sidebar-width);
      max-width: var(--#{$lte-prefix}sidebar-width);
    }

    .sidebar-menu .nav-header {
      display: inline-block;
    }

    .sidebar-menu .nav-link {
      width: subtract(var(--#{$lte-prefix}sidebar-width), $lte-sidebar-padding-x * 2);
    }

    .sidebar-menu .nav-link p,
    .brand-text {
      margin-left: 0;
      visibility: visible;
      animation-name: fadeIn;
      animation-duration: $lte-transition-speed;
      animation-fill-mode: both;
    }
  }
}

.sidebar-collapse:not(.sidebar-mini) {
  .app-sidebar {
    // stylelint-disable-next-line
    margin-left: calc(var(--#{$lte-prefix}sidebar-width) * -1);
  }
}

.sidebar-expand {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    /* stylelint-disable-next-line scss/selector-no-union-class-name */
    &#{$infix} {
      @include media-breakpoint-up($next) {
        &.layout-fixed {
          .app-sidebar {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            max-height: 100vh;

            .sidebar-wrapper {
              height: subtract(100vh, add($lte-app-header-height-inner, $lte-app-header-bottom-border-width));
            }
          }

          .app-header,
          .app-main,
          .app-footer {
            margin-left: var(--#{$lte-prefix}sidebar-width);
          }

          &.sidebar-collapse {
            .app-header,
            .app-main,
            .app-footer {
              margin-left: 0;
            }
          }

          &.sidebar-mini.sidebar-collapse {
            .app-header,
            .app-main,
            .app-footer {
              margin-left: $lte-sidebar-mini-width;
            }
          }
        }
      }

      @include media-breakpoint-down($next) {
        $max: breakpoint-max($next);

        &::before {
          display: none;
          content: "#{$max}";
        }

        .app-sidebar {
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          max-height: 100vh;
          margin-left: calc(var(--#{$lte-prefix}sidebar-width) * -1); // stylelint-disable-line function-disallowed-list

          .sidebar-wrapper {
            height: subtract(100vh, add($lte-app-header-height-inner, $lte-app-header-bottom-border-width));
            overflow-x: hidden;
            overflow-y: auto;
          }
        }

        &.sidebar-mini .app-sidebar {
          margin-left: -$lte-sidebar-mini-width;
        }

        &.sidebar-open {
          .app-sidebar {
            margin-left: 0;
          }

          .sidebar-overlay {
            position: fixed;
            top: 0;
            left: 0;
            z-index: $lte-zindex-sidebar-overlay;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .2);
            animation-name: fadeIn;
            animation-fill-mode: both;
          }
        }
      }
    }
  }
}

// To prevent onload transition and animation
.app-loaded {
  &.sidebar-mini.sidebar-collapse {
    .sidebar-menu .nav-link p,
    .brand-text {
      animation-duration: $lte-transition-speed;
    }
  }
}
body:not(.app-loaded) {
  .app-header,
  .app-sidebar,
  .app-main,
  .app-footer {
    @include transition(none !important);
    animation-duration: 0s !important;
  }
}
.hold-transition {
  .app-header,
  .app-sidebar,
  .app-main,
  .app-footer {
    @include transition(none !important);
    animation-duration: 0s !important;
  }
}

@if $enable-dark-mode {
  @include color-mode(dark) {
    &.app-sidebar,
    .app-sidebar {
      --#{$lte-prefix}sidebar-hover-bg: #{$lte-sidebar-hover-bg-dark};
      --#{$lte-prefix}sidebar-color: #{$lte-sidebar-color-dark};
      --#{$lte-prefix}sidebar-hover-color: #{$lte-sidebar-hover-color-dark};
      --#{$lte-prefix}sidebar-active-color: #{$lte-sidebar-active-color-dark};
      --#{$lte-prefix}sidebar-menu-active-bg: #{$lte-sidebar-menu-active-bg-dark};
      --#{$lte-prefix}sidebar-menu-active-color: #{$lte-sidebar-menu-active-color-dark};
      --#{$lte-prefix}sidebar-submenu-bg: #{$lte-sidebar-submenu-bg-dark};
      --#{$lte-prefix}sidebar-submenu-color: #{$lte-sidebar-submenu-color-dark};
      --#{$lte-prefix}sidebar-submenu-hover-color: #{$lte-sidebar-submenu-hover-color-dark};
      --#{$lte-prefix}sidebar-submenu-hover-bg: #{$lte-sidebar-submenu-hover-bg-dark};
      --#{$lte-prefix}sidebar-submenu-active-color: #{$lte-sidebar-submenu-active-color-dark};
      --#{$lte-prefix}sidebar-submenu-active-bg: #{$lte-sidebar-submenu-active-bg-dark};
      --#{$lte-prefix}sidebar-header-color: #{$lte-sidebar-header-color-dark};
    }
  }
}

